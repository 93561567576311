@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	* {
		@apply text-white text-base;
	}
	@media (max-width: 350px) {
		* {
			@apply text-sm;
		}
	}
	@media (max-width: 280px) {
		* {
			@apply text-[11px];
		}
	}
	@media (max-width: 250px) {
		* {
			@apply text-xs;
		}
	}
	body,
	.noite,
	.vkuiView__panels,
	html,
	.vkui--vkIOS--dark. #root,
	.vkuiPopoutRoot,
	.vkuiSplitLayout__inner,
	.vkuiSplitCol,
	.vkuiView,
	.vkuiView__panels,
	.vkuiView__panel,
	.vkuiView__panel-in,
	.vkuiPanel,
	.vkuiPanel__in,
	.vkuiPanel__centered,
	.body,
	.constelacao {
		background: #000 !important;
	}
	.body {
		@apply w-full min-h-screen;
	}
	@media (max-width: 900px) {
		body {
			/* background-image: url(/public/images/sky_background2.png) !important; */
		}
	}
	html,
	body {
		margin: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;
		position: relative;
	}
	::-webkit-scrollbar {
		width: 4px;
		border-radius: 5px;
		scrollbar-width: thin;
		scrollbar-color: #eee;
	}
	::-webkit-scrollbar-thumb {
		width: 4px;
		background-color: #666;
		scrollbar-width: thin;
		border-radius: 5px;
		scrollbar-color: #eee;
	}
	::-webkit-scrollbar-track {
		width: 4px;
		background-color: #222222;
		scrollbar-width: thin;
		border-radius: 5px;
		scrollbar-color: #eee;
	}
	.screen {
		@apply flex max-w-xl mx-auto px-3 pb-0 h-screen z-50;
	}
	@media (max-width: 600px) {
		header {
			@apply pt-2 px-3;
		}
		.screen {
			@apply px-0;
		}
	}
	header {
		@apply h-14 flex flex-shrink-0 items-center justify-start sticky top-0;
	}
	footer {
		@apply py-1 h-20 flex flex-shrink-0 items-center justify-between sticky bottom-0 bg-[#111111];
	}
	.footer-item {
		@apply opacity-50 cursor-pointer;
	}
	.footer-item.active {
		@apply opacity-100 cursor-default;
	}
	.shadow-text {
		text-shadow: 1px 1px 2px #000000;
	}
	.content {
		@apply flex-col flex-grow;
	}
	.forecast_period {
		/* @apply overflow-auto; */
	}
	.forecast_period div {
		@apply cursor-pointer;
	}
	.forecast_period .active {
		@apply border-b-2 border-stone-300 cursor-default;
	}
	.zodiac_grid .sign {
		@apply border-2 border-solid border-transparent rounded-lg cursor-pointer;
	}
	.zodiac_grid .active {
		@apply bg-[#08143C] cursor-default;
	}
	.infoBlock {
		@apply w-full h-auto overflow-auto bg-[#0D0D0D] px-5 py-5 mt-6 rounded-xl;
	}
	@media (max-width: 700px) {
		.infoBlock {
			@apply bg-opacity-100;
		}
	}
	button {
		@apply bg-indigo-700 hover:bg-indigo-600 text-white rounded-lg font-bold py-4 w-full mx-auto text-base sm:text-lg max-w-xs md:max-w-sm;
	}
	.button_small {
		@apply max-w-xs mx-auto;
	}
	.disabled {
		@apply pointer-events-none bg-[#545454] cursor-not-allowed;
	}
	h1 {
		@apply text-2xl sm:text-3xl font-medium text-center text-white;
	}
	h2 {
		@apply text-xl sm:text-2xl font-medium text-white;
	}
	.description p {
		@apply mb-5;
	}
	.menu-zodiac-item {
		@apply border border-solid border-transparent rounded-lg cursor-pointer pl-2 pr-2 pb-1 pt-1;
	}
	.menu-zodiac-item.active {
		@apply bg-[#08143C] cursor-default;
	}
	.menu_content {
		@apply overflow-scroll;
		z-index: 99;
	}
	.compatibility-type-sign .sign {
		@apply flex flex-1 max-w-[160px] h-full cursor-pointer border-2 border-transparent align-middle content-center items-center justify-center border-dashed rounded-lg text-center p-4;
	}
	.compatibility-type-sign .sign.active {
		@apply border-sky-500 bg-[#08143C] cursor-default;
	}
	.fixed-btn {
		@apply flex w-full items-center justify-center justify-items-stretch sticky bottom-0;
	}
	.dropdown-list {
		@apply px-3 py-3 max-h-[200px] overflow-y-auto;
	}
	.dropdown-item {
		@apply px-4 py-1 cursor-pointer rounded-lg;
	}
	.dropdown-item:hover {
		@apply bg-stone-900;
	}
	.dropdown-item.active {
		@apply bg-[#08143C] cursor-default;
	}
	.vkuiPopoutWrapper .vkuiPopoutWrapper__overlay {
		opacity: 0 !important;
	}
	.popup {
		@apply flex flex-col justify-center items-center px-4 py-5 rounded-lg w-full bg-opacity-100 bg-[#0D0D0D];
	}
	@media (max-height: 320px) {
		.zodiac-illustrate {
			@apply hidden;
		}
	}
}

#root {
	z-index: 999 !important;
	position: relative !important;
}
.vkuiView {
	z-index: 99;
}
.popup-container,
.my-popup {
	z-index: 90 !important;
	@apply absolute top-0;
}
footer {
	z-index: 5;
}
.meteoro {
	position: absolute;
	background-color: #fff;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	transform: rotate(-35deg);
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-duration: 3s;
}
.meteoro:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	width: 0;
	height: 0;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-left: 85px solid white;
	position: absolute;
	left: 4px;
	top: 0;
}
.meteoro.style1 {
	animation-name: meteoroStyle1;
}
.meteoro.style2 {
	animation-name: meteoroStyle2;
}
.meteoro.style3 {
	animation-name: meteoroStyle3;
}
.meteoro.style4 {
	animation-name: meteoroStyle4;
}
@keyframes meteoroStyle1 {
	0% {
		opacity: 0.2;
		right: 0%;
		top: -60%;
	}
	30% {
		opacity: 0.6;
	}
	60% {
		opacity: 0.9;
	}
	100% {
		opacity: 0.3;
		right: 120%;
		top: 60%;
	}
}
@keyframes meteoroStyle2 {
	0% {
		opacity: 0.2;
		right: 20%;
		top: 40%;
	}
	30% {
		opacity: 0.6;
	}
	60% {
		opacity: 0.9;
	}
	100% {
		opacity: 0.1;
		right: 150%;
		top: 200%;
	}
}
@keyframes meteoroStyle3 {
	0% {
		opacity: 0.2;
		right: 10%;
		top: -40%;
	}
	30% {
		opacity: 0.6;
	}
	60% {
		opacity: 0.9;
	}
	100% {
		opacity: 0;
		right: 200%;
		top: 200%;
	}
}
@keyframes meteoroStyle4 {
	0% {
		opacity: 0.2;
		right: 20%;
		top: 0%;
	}
	30% {
		opacity: 0.6;
	}
	60% {
		opacity: 0.9;
	}
	100% {
		opacity: 0.3;
		right: 200%;
		top: 240%;
	}
}

@media (max-width: 700px) {
	@keyframes meteoroStyle1 {
		0% {
			opacity: 0.2;
			right: -70%;
			top: -20%;
		}
		30% {
			opacity: 0.6;
		}
		80% {
			opacity: 0.9;
		}
		100% {
			opacity: 0.1;
			right: 140%;
			top: 40%;
		}
	}
	@keyframes meteoroStyle2 {
		0% {
			opacity: 0.2;
			right: 30%;
			top: 0%;
		}
		30% {
			opacity: 0.6;
		}
		60% {
			opacity: 0.9;
		}
		100% {
			opacity: 0.1;
			right: 200%;
			top: 60%;
		}
	}
	@keyframes meteoroStyle3 {
		0% {
			opacity: 0.2;
			right: -40%;
			top: -5%;
		}
		30% {
			opacity: 0.6;
		}
		60% {
			opacity: 0.9;
		}
		100% {
			opacity: 0.1;
			right: 250%;
			top: 95%;
		}
	}
	@keyframes meteoroStyle4 {
		0% {
			opacity: 0;
			right: 20%;
			top: -10%;
		}
		30% {
			opacity: 1;
		}
		60% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			right: 160%;
			top: 30%;
		}
	}
}

.meteoro2 {
	position: absolute;
	background-color: #fff;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	transform: rotate(135deg);
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-duration: 3s;
}
.meteoro2:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	width: 0;
	height: 0;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-left: 85px solid white;
	position: absolute;
	left: 4px;
	top: 0;
}
.meteoro2.style1 {
	animation-name: meteoro2Style1;
}
.meteoro2.style2 {
	animation-name: meteoro2Style2;
}
.meteoro2.style3 {
	animation-name: meteoro2Style3;
}
.meteoro2.style4 {
	animation-name: meteoro2Style4;
}
@keyframes meteoro2Style1 {
	0% {
		opacity: 0.2;
		left: -15%;
		bottom: -30%;
	}
	30% {
		opacity: 0.6;
	}
	60% {
		opacity: 0.9;
	}
	100% {
		opacity: 0.3;
		left: 80%;
		bottom: 200%;
	}
}
@keyframes meteoro2Style2 {
	0% {
		opacity: 0.2;
		left: 20%;
		bottom: 0%;
	}
	30% {
		opacity: 0.6;
	}
	60% {
		opacity: 0.9;
	}
	100% {
		opacity: 0.3;
		left: 120%;
		bottom: 200%;
	}
}
@keyframes meteoro2Style3 {
	0% {
		opacity: 0.2;
		left: 30%;
		bottom: 0%;
	}
	30% {
		opacity: 0.6;
	}
	60% {
		opacity: 0.9;
	}
	100% {
		opacity: 0.3;
		left: 100%;
		bottom: 150%;
	}
}
@keyframes meteoro2Style4 {
	0% {
		opacity: 0.2;
		left: 50%;
		bottom: 10%;
	}
	30% {
		opacity: 0.6;
	}
	60% {
		opacity: 0.9;
	}
	100% {
		opacity: 0.3;
		left: 150%;
		bottom: 225%;
	}
}
@media (max-width: 700px) {
	@keyframes meteoro2Style1 {
		0% {
			opacity: 0.2;
			left: -70%;
			bottom: 30%;
		}
		30% {
			opacity: 0.6;
		}
		60% {
			opacity: 0.9;
		}
		100% {
			opacity: 0.3;
			left: 180%;
			bottom: 140%;
		}
	}
	@keyframes meteoro2Style2 {
		0% {
			opacity: 0.2;
			left: 20%;
			bottom: 50%;
		}
		30% {
			opacity: 0.6;
		}
		60% {
			opacity: 0.9;
		}
		100% {
			opacity: 0.3;
			left: 240%;
			bottom: 160%;
		}
	}
	@keyframes meteoro2Style3 {
		0% {
			opacity: 0.2;
			left: -70%;
			bottom: 55%;
		}
		30% {
			opacity: 0.6;
		}
		60% {
			opacity: 0.9;
		}
		100% {
			opacity: 0.3;
			left: 140%;
			bottom: 145%;
		}
	}
	@keyframes meteoro2Style4 {
		0% {
			opacity: 0.2;
			left: 40%;
			bottom: 55%;
		}
		30% {
			opacity: 0.6;
		}
		60% {
			opacity: 0.9;
		}
		100% {
			opacity: 0.3;
			left: 160%;
			bottom: 110%;
		}
	}
}
.noite {
	background: #000;
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
}
.constelacao {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000;
	animation: rotate 60s infinite linear;
}
@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}
	50% {
		-webkit-transform: rotate(15deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}
.estrela {
	background-color: white;
	border-radius: 50%;
	position: absolute;
	animation-name: estrela;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
.estrela.tam1 {
	width: 1px;
	height: 1px;
}
.estrela.tam2 {
	width: 2px;
	height: 2px;
}
.estrela.tam3 {
	width: 3px;
	height: 3px;
}
.estrela.opacity1 {
	opacity: 1;
}
.estrela.opacity2 {
	opacity: 0.9;
}
.estrela.opacity3 {
	opacity: 0.7;
}
@keyframes escurecer {
	0% {
		top: 0;
	}
	100% {
		top: 100%;
	}
}
.estrela.style1 {
	animation-duration: 2.5s;
	animation-name: estrela;
}
.estrela.style2 {
	animation-duration: 5s;
	animation-name: estrela;
}
.estrela.style3 {
	animation-duration: 4.5s;
	animation-name: estrela;
}

@media (min-width: 800px) {
	.estrela.style4 {
		animation-duration: 5s;
		animation-name: estrelaDestacada;
	}
}
@keyframes estrela {
	0% {
		background-color: #ffffff;
	}
	50% {
		background-color: #ffffff;
	}
	100% {
		background-color: #ffffff;
	}
}
@keyframes estrelaDestacada {
	0% {
		background-color: #ffffff;
		box-shadow: 0 0 7px 2px rgba(255, 255, 255, 0.2);
		transform: scale(1);
	}
	50% {
		background-color: #ffffff;
		box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.5);
		transform: scale(1.3);
	}
	100% {
		background-color: #ffffff;
		box-shadow: 0 0 12px 2px rgba(255, 255, 255, 0.2);
		transform: scale(1);
	}
}

.clouds {
	position: absolute;
	top: 0;
	left: 0px;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: block;
	background: transparent url(/public/images/clouds.png) no-repeat bottom
		center;
	z-index: 3;
	-moz-animation: move-clouds-back 50s linear infinite;
	-ms-animation: move-clouds-back 50s linear infinite;
	-o-animation: move-clouds-back 50s linear infinite;
	-webkit-animation: move-clouds-back 50s linear infinite;
	animation: move-clouds-back 50s linear infinite;
}
@media (max-width: 700px) {
	.clouds {
		background: transparent url(/public/images/clouds_small.png) no-repeat
			bottom center;
		-moz-animation: move-clouds-back 15s linear infinite;
		-ms-animation: move-clouds-back 15s linear infinite;
		-o-animation: move-clouds-back 15s linear infinite;
		-webkit-animation: move-clouds-back 15s linear infinite;
		animation: move-clouds-back 15s linear infinite;
	}
}

@keyframes move-clouds-back {
	from {
		background-position: 0px 0;
	}
	to {
		background-position: 250% 0;
	}
}
@-webkit-keyframes move-clouds-back {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 250% 0;
	}
}
@-moz-keyframes move-clouds-back {
	from {
		background-position: 250% 0;
	}
	to {
		background-position: 250% 0;
	}
}
@-ms-keyframes move-clouds-back {
	from {
		background-position: 0;
	}
	to {
		background-position: 250% 0;
	}
}
